const functions = {
  /**
   *
   * @param key     - storage key
   * @param value   - storage value
   * @param ttl     - ttl of data being stored
   * @param cacheVersion - cache version (this helps to invalidate data that needs to be changed)
   * @return {Promise<*>}
   */
  setWithExpiry: (key, value, ttl, cacheVersion) => {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
      version: cacheVersion
    };
    localStorage.setItem(key, JSON.stringify(item));
    return Promise.resolve(value);
  },
  /**
   *
   * @param key           - storage key
   * @param cacheVersion  - cache version (this helps to invalidate data that needs to be changed)
   * @return {Promise<*>}
   */
  getWithExpiry: (key, cacheVersion) => {
    const itemStr = localStorage.getItem(key);

    return new Promise((resolve) => {
      // if the item doesn't exist, return null
      if (!itemStr) {
        return resolve(null);
      }

      const item = JSON.parse(itemStr);

      // version flag allows
      if (item.version && item.version !== cacheVersion) {
        return resolve(null);
      }

      const now = new Date();

      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return resolve(null);
      }
      return resolve(item.value);
    });
  }
};

export default functions;
