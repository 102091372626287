import config from '../constants/analytics';
import logger from './loggerService';
import cacheService from './cacheService';

const key = 'is_logging_enabled';
const daysInMilli = 86400000 * 30;
const cacheVersion = 2;
const functions = {
  /**
   * To validate application name
   *
   * @param {string} name
   */
  isValidAppName(name) {
    return /^[a-z][a-z0-9\-_]{2,49}$/.test(name.trim().toLowerCase());
  },

  /**
   * @typedef {Object} AppDetails
   * @property {boolean} isLoggingEnabled
   * @property {string} applicationId
   * @property {string} script
   */

  /**
   * To get application details
   *
   * @param {string} analyticsHost
   * @param {string} applicationName
   * @return {Promise<AppDetails>} result
   */
  getAppDetails(analyticsHost, applicationName) {
    const isValid = this.isValidAppName(applicationName);

    if (!isValid) {
      return Promise.reject(new Error(config.analytics.app_name_msg));
    }

    //TODO : Code to be refactored in the future to only support APIXCHANGE !
    let tag = config.analytics.tag_url;
    if (analyticsHost.indexOf('apps-api.splunk.com') !== -1) {
      tag = config.analytics.apix_tag_url;
    }

    return cacheService.getWithExpiry(applicationName, cacheVersion)
      .then(data => {
        if (data === null) {
          // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
          // eslint-disable-next-line promise/no-nesting
          return fetch(analyticsHost + tag,
            {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name: applicationName
              }),
              credentials: 'include'
            }).then(res => {
            if (res.ok) {
              return res.json();
            } else {
              throw new Error('Failed to retrieve application details');
            }
          });
        } else {
          return { ...data, cached: true };
        }
      })
      .then(data => {
        if (!data.cached) {
          return cacheService.setWithExpiry(applicationName, data, daysInMilli, cacheVersion);
        }
        return data;
      })
      .then(res => {
        const result = res && res.data ? res.data : false;
        if (result && result.isLoggingEnabled) {
          localStorage.setItem(key, result.isLoggingEnabled);
        } else {
          localStorage.removeItem(key);
        }
        return result;
      });
  },

  /**
   * To add a sp.js script
   *
   * @param {string}      src
   * @param {string}      position (head/body)
   * @param {boolean}     async
   * @param {boolean}     defer
   */
  addScript(src, position = 'head', async, defer) {
    return new Promise(function (resolve) {
      const placement = document.querySelector(position);
      window.scriptLoaded = function () {
        console.log('Script loaded!');
        logger.flush();
        resolve();
      };
      let script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      src += 'loadScript = scriptLoaded';
      script.text = src;
      script.async = (typeof async === 'undefined' ? true : async);
      script.defer = (typeof defer === 'undefined' ? true : defer);
      placement.appendChild(script);
    });
  },

  /**
   * To track analytics data
   *
   * @param {object} options
   * @param {boolean} isLoggedIn
   */
  track(options, isLoggedIn) {
    const {
      svp, elinkid, el, ec, eht, ea, e0, e1, e2, e3, e4, e5, e6, e7, e8, e9,
      a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, u0, u1, u2, u3, u4, u5, u6, u7, u8, u9
    } = options;
    let params = {
      svp, elinkid, el, ec, eht, ea, e0, e1, e2, e3, e4, e5, e6, e7, e8, e9,
      a0, a1, a2, a3, a4, a5, a6, a7, a8, a9, u0, u1, u2, u3, u4, u5, u6, u7, u8, u9
    };
    const { user } = options;
    if (isLoggedIn && user) {
      const loggedInParams = { uid: user.uid, uname: user.uname };
      params = {
        ...params,
        ...loggedInParams
      };
    }
    if (window.sp) {
      window.sp.track('track', params);
    } else {
      console.log('SplunkAnalytics::ERROR: sp object not found. Unable to track events.');
    }
  },
  /**
   * Trigger page view
   *
   * @param {string} url
   */
  pageview(url) {
    if (window.sp) {
      window.sp.pageview(url);
    } else {
      console.log('SplunkAnalytics::ERROR: sp object not found. Unable to track events.');
    }
  }
};

export default functions;
