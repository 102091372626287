let logs = {
  error: [],
  info: [],
  debug: []
};
const types = {
  error: 'error',
  info: 'info',
  debug: 'debug'
};
let logger = (type, data) => {
  if (!window.sp || !window.sp.log) {
    logs[type].push(data);
    return;
  }
  sp.log[type](data);
};
const functions = {
  info: (data) => {
    logger(types.info, data);
  },
  error: (data) => {
    logger(types.error, data);
  },
  debug: (data) => {
    logger(types.info, data);
  },
  flush: () => {
    if (!window.sp || !window.sp.log) {
      console.log('sp.js not loaded yet');
      return;
    }
    if (logs.error.length > 0) {
      sp.log.error(logs.error);
      logs.error = [];
    }
    if (logs.info.length > 0) {
      sp.log.info(logs.info);
      logs.info = [];
    }
    if (logs.debug.length > 0) {
      sp.log.debug(logs.debug);
      logs.debug = [];
    }
  }
};

export default functions;
